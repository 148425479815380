import i18n from "@/libs/i18n";
import { $themeColors } from "@themeConfig";
export const lineChartOptions = {
	chart: {
		type: "line",
		toolbar: {
			show: true,
			tools: {
				download: false,
				selection: '<i class="fa fa-arrows-h" aria-hidden="true"></i>',
				zoom: '<i class="fa fa-arrows-h" aria-hidden="false"></i>',
				zoomin: '<i class="fa fa-arrows-h" aria-hidden="false"></i>',
				zoomout: false,
				pan: false,
				reset: true,
			},
		},
	},
	tooltip: {
		x: {
			show: true,
			formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
				return `${i18n.t("Set")} ${value}`;
			},
		}
	},
	colors: [
		$themeColors.primary,
		$themeColors.success,
		$themeColors.info,
		$themeColors.warning,
	],
	dataLabels: {
		enabled: true,
		dropShadow: { enabled: true, left: 2, top: 2, opacity: 0.5 },
		background: {
			enabled: true,
			foreColor: "#FFF",
			padding: 4,
			borderRadius: "100%",
			borderWidth: 1,
			borderColor: "#FFF",
			opacity: 0.75,
		},
	},
	stroke: { curve: "smooth", width: 3 },
	grid: {
		borderColor: $themeColors.secondary,
		strokeDashArray: 10,
		yaxis: { lines: { show: true, color: $themeColors.secondary } },
	},
	xaxis: {
		labels: { show: true, style: { colors: $themeColors.secondary } },
		title: {
			text: i18n.t("Sets"),
			style: { color: $themeColors.secondary },
		},
	},
	yaxis: {
		min: -20,
		max: 120,
		tickAmount: 14,
		labels: { show: true, style: { colors: $themeColors.secondary } },
	},
	legend: {
		itemMargin: { horizontal: 5 },
		position: "top",
		horizontalAlign: "left",
		show: true,
		labels: {
			colors: $themeColors.secondary,
		},
		onItemHover: { highlightDataSeries: true },
		onItemClick: { toggleDataSeries: true },
	},
	annotations: {
		position: "back",
		yaxis: [
			{ y: -20, y2: 0, fillColor: $themeColors.danger, opacity: 0.3 },
			{ y: 100, y2: 120, fillColor: $themeColors.success, opacity: 0.3 },
			{
				y: 0,
				borderColor: $themeColors.danger,
				borderWidth: 2,
				strokeDashArray: 0,
			},
			// 	{
			// 		y: 20,
			// 		borderColor: $themeColors.warning,
			// 		borderWidth: 2,
			// 		strokeDashArray: 0,
			// 		label: {
			// 			borderColor: $themeColors.warning,
			// 			borderWidth: 2,
			// 			style: { background: $themeColors.warning, color: "#FFFFFF" },
			// 			text: "E",
			// 			offsetX: -20,
			// 			offsetY: 35,
			// 		},
			// 	},
			// 	{
			// 		y: 40,
			// 		borderColor: $themeColors.info,
			// 		borderWidth: 2,
			// 		strokeDashArray: 0,
			// 		label: {
			// 			borderColor: $themeColors.info,
			// 			borderWidth: 2,
			// 			style: { background: $themeColors.info, color: "#FFFFFF" },
			// 			text: "C",
			// 			offsetX: -20,
			// 			offsetY: -20,
			// 		},
			// 	},
			// 	{
			// 		y: 40,
			// 		borderWidth: 0,
			// 		strokeDashArray: 0,
			// 		label: {
			// 			borderColor: $themeColors.info,
			// 			borderWidth: 2,
			// 			style: { background: $themeColors.info, color: "#FFFFFF" },
			// 			text: "D",
			// 			offsetX: -20,
			// 			offsetY: 35,
			// 		},
			// 	},
			// 	{
			// 		y: 60,
			// 		borderColor: $themeColors.info,
			// 		borderWidth: 2,
			// 		strokeDashArray: 0,
			// 		label: {
			// 			borderColor: $themeColors.success,
			// 			borderWidth: 2,
			// 			style: { background: $themeColors.success, color: "#FFFFFF" },
			// 			text: "B",
			// 			offsetX: -20,
			// 			offsetY: -20,
			// 		},
			// 	},
			// 	{
			// 		y: 80,
			// 		borderColor: $themeColors.success,
			// 		borderWidth: 2,
			// 		strokeDashArray: 0,
			// 		label: {
			// 			borderColor: $themeColors.success,
			// 			borderWidth: 2,
			// 			style: { background: $themeColors.success, color: "#FFFFFF" },
			//      text: "A",
			// 			offsetX: -20,
			// 			offsetY: -20,
			// 		},
			// 	},
			{
				y: 100,
				borderColor: $themeColors.success,
				borderWidth: 2,
				strokeDashArray: 0,
			},
		],
	},
};
